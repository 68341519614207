import BigTitle from './BigTitleACS';


        
const Hero = () => {


return (

<div>
  

<BigTitle title1='ACS INTERNATIONAL SCHOOLS' title2='Intranet & Virtual Learning Enviroment Redesign' title3='ACS International Schools is a group of four independent schools catering for local and international families - three in England and one in Qatar.' />


    
</div>
)


}


export default Hero;