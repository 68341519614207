import BigTitle from './BigTitleVM';


        
const Hero = () => {


return (

<div>
  

<BigTitle title1='VIRGIN MEDIA' title2='Blog Design and Build for Search Engine Optimised Articles' title3='Virgin Media is a telecommunications company from England, founded in 2007, which provides telephone, television and internet services in the United Kingdom.' />


    
</div>
)


}


export default Hero;