import BigTitle from './BigTitleCS';


        
const Hero = () => {


return (

<div>
  

<BigTitle title1='CRYSTAL SKI' title2='User Interface & Visual Design for a Complete Brand Refresh' title3='Crystal ski holidays have over 100 resorts across Europe and North America and have long been established as one of the UKs leading tour operators.' />


    
</div>
)


}


export default Hero;