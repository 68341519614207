import BigTitle from './BigTitleArmy';


        
const Hero = () => {


return (

<div>
  

<BigTitle title1='BRITISH ARMY' title2='Course Performance & Wellbeing Application' title3='The British Army protects the United Kingdoms interests at home and abroad, providing a safe and secure environment.' />


    
</div>
)


}


export default Hero;